module.exports = [{
      plugin: require('../plugins/gatsby-source-dynamodb-query/gatsby-browser.js'),
      options: {"plugins":[],"typeName":"inventory","region":"us-east-2","tableName":"AdjustedInventory","dealerId":["50846","44670","65539","115311","44665","43574","51665","166084","129109","44850","63641","63641","86282","94029","53355","44664","51353","44847","61733","44666","142897","241441","142901","52134","37946","45127","145524","44667","144655","45128","52132","186209","186208","215191","235918"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"static/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"306a43fd63aadddc50d635346b7033e5"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
